
import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import Link from 'gatsby-link'

import { localStorage } from 'components/polyfills/local-storage'
import { StyledDiv } from 'components/styled/div'
import { Grid, GridItem } from 'components/grid'
import { Input } from 'components/form/input'
import { Button } from 'components/form/button'
import {
    Accordion,
    AccordionItem,
    AccordionItemTitle,
    AccordionItemTitleIcons,
    AccordionItemBody,
} from 'components/accordion'

const Ul = styled.ul`
    padding: 0;
    margin: 0 10px;
`

const Li = styled.li`
    list-style: none;
    text-transform: capitalize;
    transition: height 0.4s ease-out;
    overflow: hidden;
`

const Form = styled.form`
    margin: 0 10px;
`

// allSitePage:
// regex filter: finds pages that do not match "404" or "builder", 
// and have 2 or more characters (to exclude the root page)
const query = graphql`
    query {
        public: allSitePage(filter: { path: { regex: "/^\/generator\/.{2,}/" } }) {
            edges {
                node {
                    path
                }
            }
        }
    }
`

class BaseBrowse extends React.Component {

    constructor(props) {
        super(props)
        this.newGeneratorInput = React.createRef()
    }

    render() {

        let keyRegex = RegExp(`^${process.env.GENERATOR_PREFIX_LOCAL}`)
        let localGenerators = Object.keys(localStorage.items()).filter(key => keyRegex.test(key)).reduce((obj, key) => {
            return {
                ...obj,
                [key.replace(process.env.GENERATOR_PREFIX_LOCAL, '')]: localStorage.items()[key]
            }
        }, [])

        return <StyledDiv id='browse' injectStyle={this.props.injectStyle}>
            <h2 style={{ textAlign: 'center' }}>BROWSE</h2>
            <Grid injectStyle={`align-items: flex-start; padding: 0 5px;`}>

                <GridItem size={1} margin={'5px'} minWidth={'400px'}><Accordion injectStyle={`min-height: 400px;`}>
                    <AccordionItem expanded={true}>

                        <AccordionItemTitle>{'Public Generators'}</AccordionItemTitle>

                        <AccordionItemBody>

                            <Ul>
                                {this.props.data.public.edges.map(edge => {
                                    return <Li key={edge.node.path}>
                                        <Link to={edge.node.path}>
                                            <Accordion>
                                                <AccordionItem>
                                                    <AccordionItemTitle>
                                                        {edge.node.path.replace(`${process.env.GENERATOR_FOLDER}`, '').split('/').join(' ')}
                                                    </AccordionItemTitle>
                                                </AccordionItem>
                                            </Accordion>
                                        </Link>
                                    </Li>
                                })}
                            </Ul>

                        </AccordionItemBody>

                    </AccordionItem>
                </Accordion></GridItem>

                <GridItem size={1} margin={'5px'} minWidth={'400px'}><Accordion injectStyle={`min-height: 400px;`}>
                    <AccordionItem expanded={true}>
                    
                        <AccordionItemTitle>{'Your Content'}</AccordionItemTitle>

                        <AccordionItemBody>

                            <Ul>
                                {Object.keys(localGenerators).map(generator => {
                                    return <Li key={generator}>
                                        <Accordion>
                                            <AccordionItem>
                                                <AccordionItemTitle style={{ cursor: 'default' }}>
                                                    <p>{generator}</p>
                                                    <AccordionItemTitleIcons style={{ marginTop: 0, marginBottom: 0 }}>
                                                        <Link to={`/generator/local/${generator}`}><Button type='button'>Preview</Button></Link>
                                                        <Link to={`/builder/local/${generator}`}><Button type='button'>Edit</Button></Link>
                                                        <Button type='button' onClick={this.deleteGenerator} value={generator}>Delete</Button>
                                                    </AccordionItemTitleIcons>
                                                </AccordionItemTitle>
                                            </AccordionItem>
                                        </Accordion>
                                    </Li>
                                })}
                            </Ul>

                            <Form onSubmit={this.newGenerator}>
                                <Accordion>
                                    <AccordionItem>
                                        <AccordionItemTitle>
                                            <Input type='text' ref={this.newGeneratorInput}
                                                label='title' placeholder='Title'
                                                injectStyle={`width: 100%; margin-top: 0; margin-bottom: 0;`} 
                                            />
                                            <AccordionItemTitleIcons>
                                                <Button type='submit'>{'Add New'}</Button>
                                            </AccordionItemTitleIcons>
                                        </AccordionItemTitle>
                                    </AccordionItem>
                                </Accordion>
                            </Form>

                        </AccordionItemBody>

                    </AccordionItem>
                </Accordion></GridItem>

            </Grid>
        </StyledDiv>
    }

    newGenerator = (event) => {
        event.preventDefault()
        
        let title = this.newGeneratorInput.current.value
        localStorage.setItem(`${process.env.GENERATOR_PREFIX_LOCAL}${title}`, JSON.stringify({ title }))

        // reset the input value
        this.newGeneratorInput.current.value = ''
        this.forceUpdate()
    }

    deleteGenerator = (event) => {
        event.preventDefault()
        localStorage.removeItem(`${process.env.GENERATOR_PREFIX_LOCAL}${event.target.value}`)
        this.forceUpdate()
    }

}

export const Browse = (props) => (
    <StaticQuery
        query={query}
        render={data => <BaseBrowse {...props} data={data} />}
    />
)