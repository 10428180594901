
import styled, { css } from 'styled-components';

const isNumber = (num) => (!isNaN(num) && isFinite(num))

const Flexbox = css`
    display:            flex;
    flex-grow:          ${props => isNumber(props.grow) ? props.grow : 1};
    flex-shrink:        ${props => isNumber(props.shrink) ? props.shrink : 0};
    flex-direction:     ${props => props.direction};
    ${props => {
        // "justify-content" aligns to the "main" axis i.else. the direction of the flexbox
        // "align-items" aligns to whichever axis the main axis is not
        if (!props.direction || props.direction === 'row') {
            return `justify-content: ${props.horizontal || 'left'}; align-items: ${props.vertical || 'center'};`
        
        } else if (props.direction === 'column') {
            return `justify-content: ${props.vertical || 'flex-start'}; align-items: ${props.horizontal || 'flex-start'};`
        }
    }}
`

export const GridItem = styled.div`
    position:       relative;
    min-width:      ${props => props.minWidth};
    max-width:      100%;

    padding:        ${props => props.padding};
    margin:         ${props => props.margin};

    flex-wrap:      ${props => props.wrap ? 'wrap' : 'nowrap'};
    flex-basis:     ${props => {

        let size = isNumber(props.size)
            ? `${Math.min((props.size || 0)/12, 1) * 100}%`
            : props.size || '100%'

        if (props.margin) {
            let horizontalMargin = []
            let values = props.margin.split(' ')

            // css in single-value format
            if (values.length === 1) {
                horizontalMargin = [values[0], values[0]]

            // css in format "top right bottom left"
            } else {

                // grab "right" value
                if (values[1] && values[1].length > 1) {
                    horizontalMargin.push(values[1])
                }

                // grab "left" value
                if (values[3] && values[3].length > 1) {
                    horizontalMargin.push(values[3])
                }

            }

            return horizontalMargin.count > 0 
                ? `calc(${size} - ${horizontalMargin.join(' - ')})`
                : size
        }

        return size

    }};

    ${Flexbox}
    ${props => props.breakpoints}
    ${props => props.injectStyle}
`

export const Grid = styled.div`
    width:      100%;
    padding:    ${props => props.padding || 0};
    margin:     ${props => props.margin || 0};

    flex-wrap:  ${props => props.nowrap ? 'nowrap' : 'wrap'};

    > ${GridItem} {
        ${props => {
            if (props.variant && props.variant.item) {
                return props.variant.item
            }
        }}
    }

    ${Flexbox}
    ${props => props.breakpoints}
    ${props => props.injectStyle}
`