
import styled from 'styled-components'

export const Input = styled.input.attrs(props => ({
    type: 'text'
}))`
    display:        block;
    width:          400px;
    padding:        10px;
    margin:         10px;
    margin-left:    0;

    ${props => props.injectStyle}
`

export const TextArea = styled.textarea.attrs(props => ({
    cols: props.cols || 40,
    rows: props.rows || 2
}))`
    display:        block;
    width:          400px;
    padding:        10px;
    margin:         10px;
    margin-left:    0;

    ${props => props.injectStyle}
`